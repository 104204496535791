* {
  box-sizing: border-box;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header-border-t,
.header-border-t-flex {
  @apply border-t border-black pt-6;
}

.header-border-t,
.header-border-t-flex > h2 {
  @apply font-header text-3xl whitespace-nowrap;
}

.header-border-t-flex {
  @apply mt-6 flex flex-col sm:flex-row gap-8 sm:gap-16;
}

.content-container {
  @apply px-6 sm:px-10 mx-auto max-w-[1200px];
}

.header-description {
  @apply mt-4;
}

.content-grid {
  @apply grid grid-cols-1 sm:grid-cols-2 gap-y-12 sm:gap-y-8 gap-x-12 my-12 mb-40 sm:mb-12;
}
.grid-with-divider > div {
  @apply border-l border-black pl-6 md:pl-8;
}
.grid-with-divider > :first-child {
  @apply md:border-none;
}

.grid-with-divider > div {
  @apply mr-8;
}
.grid-with-divider > :last-child {
  @apply md:mr-0;
}

.content-list {
  @apply mt-20 sm:mt-12;
}
.content-list > p {
  @apply font-light text-sm mb-2;
}
.content-list > ul {
  @apply flex flex-col font-bold gap-4 sm:gap-2;
}
.tag {
  @apply text-sm font-text;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
