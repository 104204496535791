.smallHeader {
  @apply font-header  text-4xl whitespace-nowrap;
}

.smallDescription {
  @apply font-header text-xl font-bold h-auto md:h-16 mt-2 mb-3;
}

.smallDescription.noHeight {
  @apply h-auto md:h-auto;
}

.smallContent {
  @apply font-text font-light text-sm max-w-[450px];
}
